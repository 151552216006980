<template>
  <b-modal
    id="hospitalization_summary_guide_modal"
    hide-header
    hide-footer
    centered size="xl"
    @hidden="closeModal"
    @show="onShow"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="tissGuide?.id">Atualizar guia de resumo de internação - Nº {{ form.provider_guide }}</span>
        <span v-else>Nova guia de resumo de internação - Nº {{ form.provider_guide }}</span>
      </div>
      <span class="icon-box"><Close class="icon stroke" @click="closeModal" /></span>
    </div>

    <div class="around-content">
      <GuideDataModule
        :form="form"
        :fields="{tiss_invoice_id: true, request_id: true, situation : true}"
        :validated="validated"
        :readonly="readonly"
        :healthPlanId="healthPlan ? healthPlan.id : null"
        :tissBatchId="tissGuide?.tiss_batch_id"
        :tissGuide="tissGuide"
      />

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados da operadora</p>
        </b-col>

        <b-col cols="3">
          <label for="ans">1 - Registro ANS</label>
          <b-form-input
            maxlength="6"
            v-model="form.ans"
          />
        </b-col>

        <b-col cols="4">
          <label for="provider_guide">2 - Nº Guia do prestador</label>
          <ProviderGuideInput
            :providerGuide="form.provider_guide"
            :readonly="providerGuideReadonly"
            :validateProviderGuide="validateProviderGuide"
            type="hospitalization_summary"
            :validated="validated"
            :guideNumberExistsInBatch="guideNumberExistsInBatch"
            @update:provider-guide="form.provider_guide = $event"
          />
        </b-col>

        <b-col cols="5">
            <label for="hosp_req">3 - Nº da guia de sol. de internação</label>
            <b-form-input
              id="hosp_req"
              v-if="!form.hosp_req_id"
              v-model="form.hosp_req_num"
              :readonly="readonly"
              placeholder="Descrever"
            />
            <multiselect
              v-else
              :value="hospitalizationGuideNumber"
              placeholder="Selecionar"
              :options="hospitalizationGuidesNumbers"
              @select="selectValue($event, 'hosp_req_id')"
              label="label"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
				      <template slot="noOptions"> Nenhuma guia de solicitação encontrada, crie uma para continuar </template>
              <template slot="noResult"> Nenhum resultado </template>
              <!-- <template slot="afterList">
                <li @click="openHospitalizationRequestModal()">
                  <div class="multiselect__option action">
                    <Plus class="icon" />
                      Criar guia de solicitação de internação
                  </div>
                </li>
              </template> -->
            </multiselect>
          <div v-if="validated && !(form.hosp_req_id || form.hosp_req_num)" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="3" class="mt-3">
          <label for="authorization_date">4 - Data da autorização</label>
          <date-picker
            placeholder="Selecionar"
            :lang="langDatePicker"
            v-model="form.authorization_date"
            format="DD/MM/YYYY"
            :clearable="false"
            class="full"
            :disabled="readonly"
          />
          <div v-if="validated && !form.authorization_date" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="2" class="mt-3 input-password">
          <label for="password">5 - Senha</label>
          <b-input-group>
            <b-form-input
              maxlength="20"
              placeholder="Descrever"
              autocomplete="off"
              type="text"
              v-model="form.password"
              :readonly="readonly"
            />
          </b-input-group>
          <div v-if="validated && !form.password" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="3" class="mt-3">
          <label for="password_expiration_date">6 - Validade da senha</label>
          <date-picker
            placeholder="Selecionar"
            :lang="langDatePicker"
            v-model="form.password_expiration_date"
            format="DD/MM/YYYY"
            :clearable="false"
            class="full"
            :disabled="readonly"
          />
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="operator_guide_number">7 - Nº da guia atribuida pela operadora</label>
          <b-form-input
            maxlength="20"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.operator_guide_number"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados do beneficiário (Paciente)</p>
        </b-col>

        <b-col cols="3">
          <label for="wallet_number">8 - Nº da carteira</label>
          <b-form-input
            maxlength="20"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.wallet_number"
            :readonly="readonly"
          />
          <div v-if="validated && !form.wallet_number" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="3">
            <label for="wallet_expiration_date">9 - Validade da carteira</label>
            <date-picker
              :lang="langDatePicker"
              placeholder="Selecionar"
              v-model="form.wallet_expiration_date"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
          <div v-if="validated && (!form.wallet_expiration_date && required_fields.purse_expiration_date)" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="6">
          <label for="beneficiary_social_name">66- Nome Social</label>
          <b-form-input
            maxlength="70"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.beneficiary_social_name"
            :readonly="readonly"
          />
        </b-col>

        <b-col cols="9" class="mt-3">
          <label for="beneficiary_name">10- Nome </label>
          <b-form-input
            maxlength="70"
            placeholder="Descrever"
            autocomplete="off"
            v-model="form.beneficiary_name"
            :readonly="true"
          />
          <div v-if="validated && !form.beneficiary_name" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="3" class="mt-3">
          <label for="newborn_care">12 - Atendimento a RN</label>
          <multiselect
            :value="newbornCareSelected"
            placeholder="Selecionar"
            :options="yesOrNo"
            label="label"
            @select="selectValue($event, 'newborn_care')"
            :option-height="40"
            :showLabels="false"
            :searchable="true"
            :internal-search="false"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
          <div v-if="validated && !form.newborn_care" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="12">
          <hr class="hr" />
        </b-col>
      </b-row>

      <ExecutorContractorDataModule
        :form="form"
        guide="hospitalization_summary"
        :readonly="readonly"
        :contracteds="contracteds"
      />

        <b-row>
          <b-col cols="12">
            <p class="form-title mb-3">Dados da internação</p>
          </b-col>

          <b-col cols="4">
            <label for="attendance_character">16 - Caráter do Atendimento</label>
            <multiselect
              :value="attendanceCharacterSelected"
              placeholder="Selecionar"
              :options="attendancesCharacter"
              @select="selectValue($event, 'attendance_character')"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
            <div v-if="validated && !form.attendance_character" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="4">
            <label for="billing_type">17 - Tipo do faturamento</label>
            <multiselect
              :value="billingTypeSelected"
              placeholder="Selecionar"
              :options="billingtypes"
              @select="selectValue($event, 'billing_type')"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div v-if="validated && !form.billing_type" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="4">
            <label for="billing_start_date">18 - Data do inicio do faturamento</label>
            <date-picker
              :lang="langDatePicker"
              placeholder="Selecionar"
              v-model="form.billing_start_date"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
            <div v-if="validated && !form.billing_start_date" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="billing_start_time">19 - Hora do inicio do faturamento</label>
            <b-input
              type="time"
              placeholder="Selecionar"
              v-model="form.billing_start_time"
              :readonly="readonly"
            />
            <div v-if="validated && !form.billing_start_time" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="billing_end_date">20 - Data do fim do faturamento</label>
            <date-picker
              :lang="langDatePicker"
              placeholder="Selecionar"
              v-model="form.billing_end_date"
              format="DD/MM/YYYY"
              :clearable="false"
              class="full"
              :disabled="readonly"
            />
            <div v-if="validated && !form.billing_end_date" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="billing_end_time">21 - Hora do fim do faturamento</label>
            <b-input
              type="time"
              placeholder="Selecionar"
              v-model="form.billing_end_time"
              class="full"
              :readonly="readonly"
            />
            <div v-if="validated && !form.billing_end_time" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="hospitalization_type">22 - Tipo de internação</label>
            <multiselect
              :value="hospitalizationType"
              placeholder="Selecionar"
              :options="hospitalizationTypes"
              @select="selectValue($event, 'hospitalization_type')"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div v-if="validated && !form.hospitalization_type" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="3" class="mt-3">
            <label for="internment_regime">24 - Regime de internação</label>
            <multiselect
              :value="internmentRegime"
              placeholder="Selecionar"
              :options="internmentRegimes"
              @select="selectValue($event, 'internment_regime')"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div v-if="validated && !form.internment_regime" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="5" class="mt-3">
            <label for="cid_1_selected">24 - CID 10 Principal (Opcional)</label>
            <multiselect
              v-model="form.cid_1_selected"
              id="cid_1_selected"
              :options="cids"
              label="label"
              track-by="value"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allow-empty="false"
              :internal-search="false"
              @search-change="debounceGetCids"
              @select="selectCid10"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95" >
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions">
                Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="cid_2_selected">25 - CID 10 (2)(Opcional)</label>
            <multiselect
              v-model="form.cid_2_selected"
              id="cid_2_selected"
              :options="cids"
              label="label"
              track-by="value"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              :allow-empty="false"
              @search-change="debounceGetCids"
              @select="selectCid10"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95">
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions">
                Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="cid_3_selected">26 - CID 10 (3)(Opcional)</label>
            <multiselect
              v-model="form.cid_3_selected"
              id="cid_3_selected"
              :options="cids"
              label="label"
              track-by="value"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              :allow-empty="false"
              @search-change="debounceGetCids"
              @select="selectCid10"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95" >
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions">
                Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="cid_4_selected">27 - CID 10 (4)(Opcional)</label>
            <multiselect
              v-model="form.cid_4_selected"
              id="cid_4_selected"
              :options="cids"
              label="label"
              track-by="value"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              :allow-empty="false"
              @search-change="debounceGetCids"
              @select="selectCid10"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95" >
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions">
                Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
          </b-col>

          <b-col cols="3" class="mt-3">
            <label for="accident_indication">28 - Indicação de acidente</label>
            <multiselect
              :value="accidentIndication"
              placeholder="Selecionar"
              :options="accidentIndications"
              @select="selectValue($event, 'accident_indication')"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div v-if="validated && !form.accident_indication" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="closing_reason">29 - Motivo de Enc. de internação</label>
            <multiselect
              :value="closingReason"
              placeholder="Selecionar"
              :options="closingReasons"
              @select="selectValue($event, 'closing_reason')"
              label="label"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div v-if="validated && !form.closing_reason" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-col>

          <b-col cols="5" class="mt-3">
            <label for="live_birth_declaration_number">30 - Nº de declaração de nascido vivo</label>
            <b-form-input
              maxlength="11"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.live_birth_declaration_number"
              :readonly="readonly"
            />
          </b-col>

          <b-col cols="4" class="mt-3">
            <label for="obituary_cid_10">31 - CID 10 Óbito (Opcional)</label>
            <multiselect
              v-model="form.obituary_cid_10_selected"
              id="obituary_cid_10"
              :options="cids"
              label="label"
              track-by="value"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allow-empty="false"
              :internal-search="false"
              @search-change="debounceGetCids"
              @select="selectCid10"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                <span class="d-inline-block text-truncate width-95">
                  {{ option.label }}
                </span>
              </template>

              <template slot="noOptions">
                Digite para pesquisar um CID10
              </template>
              <template slot="noResult">Nenhum CID10 encontrado</template>
            </multiselect>
          </b-col>

          <b-col cols="5" class="mt-3">
            <label for="death_certificate_number">32 - Nº de declaração de óbito</label>
            <b-form-input
              maxlength="11"
              placeholder="Descrever"
              autocomplete="off"
              v-model="form.death_certificate_number"
              :readonly="readonly"
            />
          </b-col>

          <b-col cols="3" class="mt-3">
            <label for="newborn_death_certificate_indicator">33 - Indicador D.O. de RN</label>
             <multiselect
              id="newborn_death_certificate_indicator"
              :value="newbornDeathCertificateIndicator"
              placeholder="Selecionar"
              :options="yesOrNo"
              label="label"
              @select="selectValue($event, 'newborn_death_certificate_indicator')"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :internal-search="false"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-col>
          <b-col cols="12">
            <hr class="hr" />
          </b-col>
        </b-row>

        <ProceduresModule
          guide="hospitalization_summary"
          :tissGuideId="tissGuide ? tissGuide.id : null"
          :invoicingTissSetting="invoicing_tiss_setting"
          :settingsId="form.invoicing_tiss_setting_id"
          :items="form.items"
          :specialCases="invoicing_tiss_setting ? invoicing_tiss_setting.tiss_participation_percentage_special_case : null"
          :participationPercentage="invoicing_tiss_setting ? invoicing_tiss_setting.tiss_participation_percentage : null"
          :readonly="readonly"
          @addOrUpdateTissGuideItem="addOrUpdateTissGuideItem"
        />

      <b-row>
        <b-col cols="4" class="mt-3">
          <label for="total_value_procedures">54 - Total de procedimentos</label>
          <money
            v-model="form.total_value_procedures"
            class="form-control"
            readonly
          />
          <div v-if="validated && !form.total_value_procedures" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="total_daily_value">55 - Total Diárias</label>
          <money
            v-model="form.total_daily_value"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="total_amount_fess_and_rents">56 - Total de Taxas e aluguéis</label>
          <money
            v-model="form.total_amount_fess_and_rents"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="total_value_materials">57 - Total de materiais</label>
          <money
            v-model="form.total_value_materials"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="total_value_opme">58 - Total OPME</label>
          <money
            v-model="form.total_value_opme"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="total_value_medicines">59 - Total de medicamentos</label>
          <money
            v-model="form.total_value_medicines"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="total_value_medicinal_gases">60 - Total de Gases Medicinais</label>
          <money
            v-model="form.total_value_medicinal_gases"
            class="form-control"
            readonly
          />
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="final_amount">61 - Total Geral</label>
          <money
            :value="total()"
            class="form-control"
            readonly
          />
          <div v-if="validated && !form.final_amount" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="4" class="mt-3">
          <label for="provider_signature_date_hired">62 - Data da ass. do contrato</label>
          <date-picker
            :lang="langDatePicker"
            placeholder="Selecionar"
            v-model="form.provider_signature_date_hired"
            format="DD/MM/YYYY"
            :clearable="false"
            class="full"
            :disabled="readonly"
          />
          <div v-if="validated && !form.provider_signature_date_hired" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="12" class="mt-3">
          <label for="observation">65 - Observação / Justificativa</label>
          <b-form-textarea
            maxlength="500"
            placeholder="Descrever"
            rows="2"
            max-rows="5"
            autocomplete="off"
            v-model="form.observation"
            :readonly="readonly"
          />
          <div v-if="validated && !form.observation" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="tissGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updateGuide"
          v-if="tissGuide?.id && !readonly"
        >
          Atualizar guia
        </b-button>
        <b-button
          variant="primary"
          size="lg"
          v-else-if="!readonly"
          @click="createGuide"
        >
          Salvar guia
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { debounce } from 'lodash'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'HospitalizationSummaryGuideModal',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    ExecutorContractorDataModule: () => import('./Modules/ExecutorContractorDataModule'),
    GuideDataModule: () => import('./Modules/GuideDataModule'),
    ProceduresModule: () => import('./Modules/ProceduresModule.vue'),
    // Plus: () => import('@/assets/icons/plus.svg'),
    ProviderGuideInput: () => import('@/components/Tiss/Generic/ModalComponents/ProviderGuideInput')
  },
  props: {
    patient: Object,
    healthPlan: Object,
    invoicing_tiss_setting: Object,
    appointmentId: String,
    required_fields: Object,
    tissGuide: Object,
    guideNumber: Number,
    hospitalizationGuidesNumbers: Array,
    providerGuideReadonly: Boolean,
    validateProviderGuide: Function,
    guideNumberExistsInBatch: Boolean,
    guideNumberExists: Boolean,
    providerGuideUpdated: Number,
    contracteds: Array
  },
  created() {
    this.debounceGetCids = debounce(this.searchCid10, 500)
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      cids: [],
      attendancesCharacter: require('./../Data/attendancesCharacter.json'),
      hospitalizationTypes: require('./../Data/hospitalizationTypes.json'),
      internmentRegimes: require('./../Data/internmentRegimes.json'),
      accidentIndications: require('./../Data/accidentIndications.json'),
      closingReasons: require('./../Data/closingReasons.json'),

      showPassword: false,
      validated: false,
      form: {},

      yesOrNo: [
        { label: 'S - Sim', value: 'S' },
        { label: 'N - Não', value: 'N' }
      ],
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      billingtypes: [
        { label: '1 - Parcial', value: '1' },
        { label: '2 - Final', value: '2' },
        { label: '3 - Complementar', value: '3' },
        { label: '4 - Total', value: '4' }
      ]
    }
  },
  computed: {
    hospitalizationGuideNumber() {
      return this.hospitalizationGuidesNumbers?.find(item => item?.value === this.form.hosp_req_id)
    },
    readonly() {
      return this.tissGuide && Boolean(
        Object.keys(this.tissGuide).length
        && (['Finalizada'].includes(this.tissGuide.situation)|| this.tissGuide.tiss_batch_id)
      )
    },
    newbornCareSelected() {
      return this.yesOrNo.find(item => item.value === this.form.newborn_care)
    },
    newbornDeathCertificateIndicator() {
      return this.yesOrNo.find(item => item.value === this.form.newborn_death_certificate_indicator)
    },
    attendanceCharacterSelected() {
      return this.attendancesCharacter.find(item => item.value === this.form.attendance_character)
    },
    billingTypeSelected() {
      return this.billingtypes.find(item => item.value === this.form.billing_type)
    },
    hospitalizationType() {
      return this.hospitalizationTypes.find(item => item.value === this.form.hospitalization_type)
    },
    internmentRegime() {
      return this.internmentRegimes.find(item => item.value === this.form.internment_regime)
    },
    accidentIndication() {
      return this.accidentIndications.find(item => item.value === this.form.accident_indication)
    },
    closingReason() {
      return this.closingReasons.find(item => item.value === this.form.closing_reason)
    },
  },
  methods: {
    async onShow() {
      this.getPatientWallet()
      if (!this.tissGuide?.id) {
        this.setSelectsFields()
      }
    },
    selectValue(item, field) {
      this.form[field] = item.value
    },
    getDefaultForm() {
      return {
        clinic_id: this.clinic.id,
        invoicing_tiss_setting_id: this.invoicing_tiss_setting ? this.invoicing_tiss_setting.id : null,
        appointment_id: this.appointmentId,

        id: null,
        tiss_guide_id: null,

        items: [],

        request_id: null,
        bank_account_id: this.invoicing_tiss_setting ? this.invoicing_tiss_setting.bank_account_id: null,

        situation: null,
        tiss_invoice_id: null,

        ans: this.healthPlan?.ans_code,
        provider_guide: this.guideNumber,
        hosp_req_id: null,
        hosp_req_num: null,

        authorization_date: null,
        password: null,
        password_expiration_date: null,
        operator_guide_number: null,

        wallet_number: null,
        wallet_expiration_date: null,
        beneficiary_social_name: null,
        beneficiary_name: null,
        newborn_care: null,

        operator_code: this.invoicing_tiss_setting && this.invoicing_tiss_setting.tiss_using_guide_type && this.invoicing_tiss_setting.tiss_using_guide_type.hospitalization_summary_guide ?
          this.invoicing_tiss_setting.hired_executant_code: null,
        contractor_name: this.invoicing_tiss_setting && this.invoicing_tiss_setting.tiss_using_guide_type && this.invoicing_tiss_setting.tiss_using_guide_type.hospitalization_summary_guide ?
          this.invoicing_tiss_setting.hired_executant_name: null,
        contractor_cnes: this.invoicing_tiss_setting && this.invoicing_tiss_setting.tiss_using_guide_type && this.invoicing_tiss_setting.tiss_using_guide_type.hospitalization_summary_guide ?
          this.invoicing_tiss_setting.CNES: null,

        attendance_character: null,
        billing_type: null,
        billing_start_date: null,
        billing_start_time: null,
        billing_end_date: null,
        billing_end_time: null,
        hospitalization_type: null,
        internment_regime: null,
        cid_1: null,
        cid_2: null,
        cid_3: null,
        cid_4: null,
        obituary_cid_10: null,

        accident_indication: null,
        closing_reason: null,
        live_birth_declaration_number: null,
        death_certificate_number: null,
        newborn_death_certificate_indicator: null,

        total_value_procedures: 0,
        total_daily_value: 0,
        total_amount_fess_and_rents: 0,
        total_value_materials: 0,
        total_value_opme: 0,
        total_value_medicines: 0,
        total_value_medicinal_gases: 0,
        final_amount: 0,
        provider_signature_date_hired: null,
        observation: null,
      }
    },
    setSelectsFields() {
      if (this.tissGuide?.id) return
      this.form.beneficiary_social_name = this.patient?.nickname
      this.form.beneficiary_name = this.patient?.name
      this.form.newborn_care = this.patient?.birthday ? ((this.moment(this.patient.birthday).add(1, 'M') >=  this.moment()) ? 'S' : 'N') : 'N'

      this.form.attendance_character = this.attendancesCharacter.find(option => option.label === this.invoicing_tiss_setting?.attendance_character)?.value
      this.form.accident_indication = this.accidentIndications.find(option => option.label === this.invoicing_tiss_setting?.accident_indication)?.value
    },
    clearForm() {
      this.form = this.getDefaultForm()
      this.validated = false
    },
    isValidForm() {
      if (this.form.situation !== 'Finalizada') return true;

      this.validated = true

      if (this.guideNumberExists) {
        this.$toast.error('Este Nº de guia já existe, resolva o conflito para continuar.')
        return false
      }

      const walletExpirationDateValidation = this.required_fields.purse_expiration_date ? !!this.form.wallet_expiration_date : true

      return !this.guideNumberExistsInBatch &&
        (this.form.provider_guide && this.form.provider_guide > 0) &&
        this.form.situation &&
        (this.form.hosp_req_id || this.form.hosp_req_num) &&
        this.form.authorization_date &&
        this.form.password &&
        this.form.wallet_number &&
        walletExpirationDateValidation &&
        this.form.beneficiary_name &&
        this.form.attendance_character &&
        this.form.billing_type &&

        this.form.billing_start_date &&
        this.form.billing_start_time &&
        this.form.billing_end_date &&
        this.form.billing_end_time &&

        this.form.hospitalization_type &&
        this.form.internment_regime &&
        this.form.newborn_care &&

        this.form.accident_indication &&
        this.form.closing_reason &&

        this.form.total_value_procedures &&
        this.form.final_amount &&
        this.form.provider_signature_date_hired &&
        this.form.observation
    },
    print() {
      this.$emit('print', this.tissGuide)
    },
    async createGuide() {
      if(!this.isValidForm()) return;

      const isLoading = this.$loading.show();
      try {
        this.form.patient_id = this.patient.id
        this.form.cid_1 = this.form.cid_1_selected?.value ?? null;
        this.form.cid_2 = this.form.cid_2_selected?.value ?? null;
        this.form.cid_3 = this.form.cid_3_selected?.value ?? null;
        this.form.cid_4 = this.form.cid_4_selected?.value ?? null;
        this.form.obituary_cid_10 = this.form.obituary_cid_10_selected?.value ?? null;

        if(typeof this.form.tiss_invoice_id === 'object') this.form.tiss_invoice_id = this.form.tiss_invoice_id?.value ?? null

        const response = await this.api.createHospitalizationSummaryGuide(this.form);
        this.$emit('refresh-tiss-guides', { 'id' :response.data.id , 'guide_id': this.tissGuide.id })
        this.$toast.success('Guia de resumo de internação criada com sucesso!');
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        isLoading.hide();
      }
    },
    async updateGuide() {
      if(!this.isValidForm()) return;

      const isLoading = this.$loading.show();
      try {
        this.form.cid_1 = this.form.cid_1_selected ? this.form.cid_1_selected.value : null;
        this.form.cid_2 = this.form.cid_2_selected ? this.form.cid_2_selected.value : null;
        this.form.cid_3 = this.form.cid_3_selected ? this.form.cid_3_selected.value : null;
        this.form.cid_4 = this.form.cid_4_selected ? this.form.cid_4_selected.value : null;
        this.form.obituary_cid_10 = this.form.obituary_cid_10_selected ? this.form.obituary_cid_10_selected.value : null;
        if(typeof this.form.tiss_invoice_id === 'object') this.form.tiss_invoice_id = this.form.tiss_invoice_id ? this.form.tiss_invoice_id.value : null

        const response = await this.api.updateHospitalizationSummaryGuide(this.tissGuide.guide.id ,this.form);
        this.$emit('refresh-tiss-guides', { 'id' :response.data.id , 'guide_id': this.tissGuide.id })
        this.$toast.success('Guia de resumo de internação atualizada com sucesso!');
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        isLoading.hide();
      }
    },
    async searchCid10(query) {
      if (query.length > 2) {
        this.cids = []
        try {
          const response = await this.api.searchCid10({ term: query })
          this.cids = response.data.map(cid10 => {
            return {
              label: `${cid10?.code} - ${cid10.description}`,
              value: cid10.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async getCidById(id, field) {
      try {
        const response = await this.api.getCidById(id)
        if (Object.keys(response.data).length) {
          this.cids = [
            {
              label: `${response.data?.code} - ${response.data.description}`,
              value: response.data.id
            }
          ]
        }

        if (field === 'cid_1') this.form.cid_1_selected = this.cids.find(el => el.value === this.form.cid_1)
        else if (field === 'cid_2') this.form.cid_2_selected = this.cids.find(el => el.value === this.form.cid_2)
        else if (field === 'cid_3') this.form.cid_3_selected = this.cids.find(el => el.value === this.form.cid_3)
        else if (field === 'cid_4') this.form.cid_4_selected = this.cids.find(el => el.value === this.form.cid_4)
        else if (field === 'obituary_cid_10') this.form.obituary_cid_10_selected = this.cids.find(el => el.value === this.form.obituary_cid_10)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    selectCid10() {
      this.cids = []
    },
    closeModal() {
      this.clearForm();
      this.$emit('onHide');
      this.$bvModal.hide('hospitalization_summary_guide_modal');
    },
    updateTotalValue(newValue, oldValue) {
      this.form.final_amount -= oldValue;
      this.form.final_amount += parseFloat(newValue)
    },
    total() {
      const total = this.form.total_value_procedures + this.form.total_daily_value
        + this.form.total_amount_fess_and_rents + this.form.total_value_materials
        + this.form.total_value_opme + this.form.total_value_medicines + this.form.total_value_medicinal_gases

      this.form.final_amount = total
      return total
    },
    openHospitalizationRequestModal(){
      this.$emit('open-hospitalization-request-modal')
    },
    getPatientWallet () {
      if (this.tissGuide?.id) return

      const personPlan = this.patient?.person_health_plans?.find(
        plan => plan?.health_plan_id === this.healthPlan?.id
      )

      this.form.wallet_number = personPlan?.registration
      this.form.wallet_expiration_date =  this.moment(personPlan?.valid_until).toDate()
    },
    addOrUpdateTissGuideItem(tissGuideItem) {
      const items = Object.assign([], this.form.items ?? [])
      const tissGuideItemIndex = items.findIndex(item => item.item_id === tissGuideItem.procedure_id || item.procedure_id === tissGuideItem.procedure_id)
      tissGuideItemIndex > -1 ? 
        items.splice(tissGuideItemIndex, 1, tissGuideItem) :
        items.push(tissGuideItem)
      this.form = { ...this.form, items }
    },
  },
  watch: {
    tissGuide: {
      async handler(newValue) {
        if (newValue?.guide_type === "App\\Models\\TissGuide\\TissGuideHospitalizationSummary") {
          this.form = {
            ...this.form,
            ...newValue.guide
          }
          this.form.provider_guide = newValue.provider_guide
          this.form.tiss_guide_id = newValue.id
          this.form.tiss_invoice_id = newValue.tiss_invoice_id

          this.form.items = newValue.items

          this.form.situation = newValue.situation
          this.form.authorization_date = this.moment(newValue.authorization_date).toDate()
          this.form.password_expiration_date = this.moment(this.form.password_expiration_date).toDate()
          this.form.wallet_expiration_date = this.moment(this.form.wallet_expiration_date).toDate()
          this.form.billing_start_date = this.moment(this.form.billing_start_date).toDate()
          this.form.billing_end_date = this.moment(this.form.billing_end_date).toDate()
          this.form.provider_signature_date_hired = this.moment(this.form.provider_signature_date_hired).toDate()
          this.form.invoicing_tiss_setting_id = newValue.invoicing_tiss_setting_id

          await this.getCidById(this.form.cid_1, 'cid_1');
          await this.getCidById(this.form.cid_2, 'cid_2');
          await this.getCidById(this.form.cid_3, 'cid_3');
          await this.getCidById(this.form.cid_4, 'cid_4');
          await this.getCidById(this.form.obituary_cid_10, 'obituary_cid_10');
        } else {
          this.clearForm()
        }
      },
      immediate: true
    },
    'form.items': {
      handler(newValue) {
        let total = 0
        newValue.forEach(item => {
          total += item.total_value
        })
        this.form.total_value_procedures = total
      },
      deep: true
    },
    patient: {
      handler(value) {
        if (value?.id) {
          this.setSelectsFields()
        }
      },
      deep: true,
      immediate: true
    },
    guideNumber() {
      if (!Object.keys(this.tissGuide).length) {
        this.form = this.getDefaultForm()
      }
    },
    'form.provider_guide': {
      handler(newValue) {
        if (newValue) {
          const form = {
            id: this.tissGuide.id,
            provider_guide: newValue,
            patient: {
              name: this.form.beneficiary_name
            }
          }
          this.$emit('update-form', { 'form': form, providerGuide: this.providerGuideUpdated ?? this.guideNumber})
        }
      },
      deep: true,
      immediate: true
    },
    providerGuideUpdated: {
      handler(newValue) {
        if (newValue) {
          this.form.provider_guide = newValue
        }
      },
      immediate: true
    },
    invoicing_tiss_setting: {
      handler(newValue) {
        if (newValue?.id) {
          this.setSelectsFields()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#hospitalization_summary_guide_modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin: 0;
  }

  .width-95 {
    width: 95%;
  }

  .form-title {
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .multiselect {
      .action {
        border-top: 1px solid var(--neutral-200);
        color: var(--blue-500);
        display: flex;
        align-items: center;

        .icon {
          width: 24px;
          height: 24px;
          fill: var(--blue-500);
          margin-right: 16px;
        }
      }
    }
}
</style>
